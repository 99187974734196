import ArrayUtils from '@/assets/js/utils/ArrayUtils';
import Utils from '@/assets/js/utils/Utils';
import { ReportConfigLayoutRepresentation, ReportReferenceDate } from '@/clients/dashboardapi/v2';
import { WidgetUtils } from '@/components/widgets';
import type { LayoutConfig, ReportConfig, ReportConfigRepository } from '@/modules/ctx-report-preview';
import {
    Layout,
    mapReportGenerationInterval,
    ReportGenerationReferenceDate,
    ReportType,
} from '@/modules/ctx-report-preview';
import type {
    AuthApi,
    DashboardsApi,
    PortfoliosApi,
    ReportConfigRepresentation,
    ReportCreationInterval,
    ReportingApi,
    WidgetRepresentation,
    WidgetsApi,
} from '@/modules/shared/adapter';
import { AccountingApi, GeneratorsApi, MetricsApi, ParksApi } from '@/modules/shared/adapter';
import { WidgetType } from '@/modules/shared/types';
import { WidgetMapper } from '@/plugins/modules/ctx-dashboard/mapper/WidgetMapper';
import { ReportGenerationLanguage } from '@/modules/ctx-report-preview/types/ReportGenerationLanguage';

export class ReportConfigRepositoryImpl implements ReportConfigRepository {

    private readonly authApi: AuthApi;
    private readonly widgetsApi: WidgetsApi;
    private readonly reportingApi: ReportingApi;
    private readonly dashboardsApi: DashboardsApi;
    private readonly portfolioApi: PortfoliosApi;
    private readonly widgetMapper: WidgetMapper;

    constructor(apis: {
        reporting: ReportingApi;
        dashboards: DashboardsApi;
        portfolios: PortfoliosApi;
        auth: AuthApi;
        widgets: WidgetsApi,
        metrics: MetricsApi,
        generators: GeneratorsApi,
        parks: ParksApi,
        accounting: AccountingApi,
    }) {
        this.authApi = apis.auth;
        this.reportingApi = apis.reporting;
        this.dashboardsApi = apis.dashboards;
        this.portfolioApi = apis.portfolios;
        this.widgetsApi = apis.widgets;
        this.widgetMapper = new WidgetMapper({
            metrics: apis.metrics,
            portfolios: apis.portfolios,
            generators: apis.generators,
            parks: apis.parks,
            accounting: apis.accounting,
        });
    }

    public async getReportConfig(dashboardKey: string): Promise<ReportConfig|undefined> {
        const config = await this.reportingApi.getReportConfigByDashboardKey(dashboardKey);
        if (config) {
            return this.mapReportConfigToDomain(config);
        }
        return undefined;
    }

    public async saveReportConfig(config: ReportConfig): Promise<ReportConfig> {
        const updatedConfig = await this.reportingApi.updateReportConfig(config.key, {
            title: config.title,
            subtitle: config.subtitle,
            reportType: config.reportType,
            createAutomatically: config.generateAutomatically,
            creationInterval: config.generateInterval as ReportCreationInterval|undefined,
            createAt: config.generateAt?.getTime(),
            sentToEmail: config.reportEmails,
            referenceDate: config.generateForReferenceDate as ReportReferenceDate|undefined,
            language: config.generateForLanguage,
        });
        return this.mapReportConfigToDomain(updatedConfig);
    }

    public async saveReportLayout(config: ReportConfig): Promise<ReportConfig> {
        const updatedConfig = await this.reportingApi.updateReportConfig(config.key, {
            title: config.title,
            subtitle: config.subtitle,
            layouts: config.layouts
                .filter((layout) => layout.editable)
                .sort((a, b) => a.position - b.position)
                .map((layout, index) => ({
                    layout: layout.layout,
                    page: index,
                    slots: layout.widgets
                        .filter(ArrayUtils.filterUndefined)
                        .filter((widget) => widget.position !== undefined)
                        .map((widget) => ({
                            slot: widget.position!,
                            widgetKey: widget.widgetKey,
                            displayMode: widget.displayMode,
                        })),
                })),
        });
        return this.mapReportConfigToDomain(updatedConfig);
    }

    public async createReportConfig(dashboardKey: string, currentLocale: string): Promise<ReportConfig> {
        const dashboard = await this.dashboardsApi.getDashboardByKey(dashboardKey);
        if (!dashboard) {
            throw new Error('Dashboard not found!');
        }
        const updatedConfig = await this.reportingApi.createReportConfig({
            dashboardKey: dashboard.key,
            portfolioKey: dashboard.portfolioKey,
            title: '',
            subtitle: '',
            reportType: ReportType.Custom,
            referenceDate: ReportReferenceDate.Previous,
            language: currentLocale,
        });
        return this.mapReportConfigToDomain(updatedConfig);
    }

    public async generateAndStoreReport(reportConfigKey: string, language: string, referenceDate?: Date): Promise<void> {
        await this.reportingApi.generateReport({
            reportConfigKey: reportConfigKey,
            storeInDocuments: true,
            referenceDate: referenceDate?.getTime(),
            language,
        });
    }

    private async mapReportConfigToDomain(representation: ReportConfigRepresentation): Promise<ReportConfig> {
        const dashboard = await this.dashboardsApi.getDashboardByKey(representation.dashboardKey);
        if (!dashboard) {
            throw new Error('Dashboard not found');
        }
        const widgets = await this.widgetsApi.getWidgetsForDashboard(dashboard.key);
        const portfolio = await this.portfolioApi.getPortfolioByKey(dashboard.portfolioKey);
        if (!portfolio) {
            throw new Error('Portfolio not found');
        }
        const company = await this.authApi.getCompany();
        const defaultLayouts: LayoutConfig[] = [
            { key: 'cover', layout: Layout.CoverPage, widgets: [], position: 0, editable: false },
            { key: 'toc', layout: Layout.TableOfContent, widgets: [], position: 10, editable: false },
            { key: 'summary', layout: Layout.Summary, widgets: [], position: 20, editable: false },
        ];
        let layouts: LayoutConfig[] = [];
        if (representation.layouts) {
            layouts = await Promise.all(representation.layouts.map((layout) => this.mapLayoutToDomain(layout, widgets)));
            layouts.forEach((layout) => {
                layout.position = layout.position * 10 + 30;
                layout.hash = Utils.hash(layout);
            });
            layouts = defaultLayouts.concat(layouts);
        }
        return {
            key: representation.key,
            defaultTitle: '',
            defaultSubTitle: '',
            title: representation.title,
            subtitle: representation.subtitle,
            reportType: representation.reportType as ReportType || ReportType.Custom,
            layouts: layouts,
            generateAutomatically: representation.createAutomatically || false,
            reportEmails: representation.sentToEmail || [],
            generateInterval: mapReportGenerationInterval(representation.creationInterval),
            generateForReferenceDate: representation.referenceDate as ReportGenerationReferenceDate|undefined || ReportGenerationReferenceDate.Current,
            generateForLanguage: representation.language as ReportGenerationLanguage|undefined,
            generateAt: representation.createAt ? new Date(representation.createAt) : undefined,
            dashboardKey: dashboard.key,
            dashboardName: dashboard.name,
            portfolioKey: portfolio.key,
            portfolioName: portfolio.name,
            companyName: company.name,
            titleImage: company.heroImage || require('@/assets/images/backgrounds/wind.jpg'),
            companyLogo: company.logo || '',
            accentColor: company.color || '#42a6c6',
        };
    }

    private async mapLayoutToDomain(representation: ReportConfigLayoutRepresentation, dashboardWidgets: WidgetRepresentation[]): Promise<LayoutConfig> {
        const highestSlot = representation.slots.map((slot) => slot.slot).reduce(ArrayUtils.reduceToHighest, 0);
        const layout: LayoutConfig = {
            key: Utils.generateUUID(),
            position: representation.page,
            layout: representation.layout as Layout,
            editable: true,
            widgets: [],
        };
        for (let i = 0; i <= highestSlot; i++) {
            const slot = representation.slots.find((it) => it.slot === i);
            if (!slot) {
                layout.widgets[i] = undefined;
                continue;
            }
            const widget = dashboardWidgets.find((it) => it.key === slot.widgetKey);
            if (!widget) {
                console.log(`Widget with key ${slot.widgetKey} cannot be resolved`);
                layout.widgets[i] = undefined;
                continue;
            }
            layout.widgets[i] = {
                widgetKey: widget.key,
                position: slot.slot,
                page: representation.page,
                interval: widget.intervalName,
                widgetType: widget.type as WidgetType,
                displayMode: this.mapDisplayMode(slot.displayMode),
                widgetName: widget.title
                    || widget.generatedTitle
                    // eslint-disable-next-line no-await-in-loop
                    || await this.getDefaultWidgetTitle(widget),

            };
        }
        return layout;
    }

    private mapDisplayMode(str: string): 'chart'|'table' {
        if (str === 'table') {
            return 'table';
        }
        // maps graph (legacy) and chart to chart
        return 'chart';
    }

    /**
     * generated widget name may not yet be present in config for widgets, that have not been saved for some time
     * @param widget
     */
    private async getDefaultWidgetTitle(widget: WidgetRepresentation): Promise<string> {
        console.log('Applying workaround to generate widget name');
        return this.widgetMapper.mapWidgetToDomain(widget).then(WidgetUtils.getDefaultWidgetTitle);
    }
}
