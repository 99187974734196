import { I18nService } from '@/modules/ctx-documents/services/I18nService';
import type {
    CompaniesRepository,
    DocumentsRepository,
    ParksRepository,
    GeneratorsRepository,
    UserRepository,
    LicenseRepository, I18nRepository,
} from './adapter';
import { DocumentsService } from './services/DocumentsService';
import { CompanyService } from './services/CompanyService';
import { UserService } from './services/UserService';

export interface PortParams {
    documentsRepository: DocumentsRepository;
    parksRepository: ParksRepository;
    generatorsRepository: GeneratorsRepository;
    companiesRepository: CompaniesRepository;
    userRepository: UserRepository;
    licenseRepository: LicenseRepository;
    i18nRepository: I18nRepository;
}

export class Port {
    private static port: Port|null = null;

    private readonly documentsService: DocumentsService;
    private readonly companyService: CompanyService;
    private readonly i18nService: I18nService;
    private readonly userService: UserService;

    constructor(params: PortParams) {
        this.documentsService = new DocumentsService({
            documentsRepository: params.documentsRepository,
            parksRepository: params.parksRepository,
            generatorsRepository: params.generatorsRepository,
            i18nRepository: params.i18nRepository,
        });
        this.companyService = new CompanyService({
            companiesRepository: params.companiesRepository,
            licenseRepository: params.licenseRepository,
        });
        this.userService = new UserService({
            userRepository: params.userRepository,
        });
        this.i18nService = new I18nService({
            i18nRepository: params.i18nRepository,
        });
    }

    public static init(params: PortParams): void {
        this.port = new Port(params);
    }

    public static get instance(): Port {
        if (this.port === null) {
            throw new Error('Port was not initialized yet!');
        }
        return this.port;
    }

    public static get documents(): DocumentsService {
        return Port.instance.documentsService;
    }

    public static get companies(): CompanyService {
        return Port.instance.companyService;
    }

    public static get i18n(): I18nService {
        return Port.instance.i18nService;
    }

    public static get user(): UserService {
        return Port.instance.userService;
    }
}
