import type { Dashboard, DashboardRepository } from '@/modules/ctx-dashboard';
import type {
    DashboardRepresentation,
    DashboardsApi,
    ReportConfigRepresentation,
    ReportingApi,
} from '@/modules/shared/adapter';
import ArrayUtils from '@/assets/js/utils/ArrayUtils';

export class DashboardRepositoryImpl implements DashboardRepository {

    private readonly dashboardsApi: DashboardsApi;
    private readonly reportingApi: ReportingApi;

    constructor(apis: {
        dashboards: DashboardsApi,
        reporting: ReportingApi,
    }) {
        this.dashboardsApi = apis.dashboards;
        this.reportingApi = apis.reporting;
    }

    public onDashboardsChange(cb: () => Promise<void>): void {
        this.dashboardsApi.onChange(cb);
    }

    public async getDashboard(dashboardKey: string): Promise<Dashboard> {
        const representation = await this.dashboardsApi.getDashboardByKey(dashboardKey);
        if (!representation) {
            throw new Error('404 - Dashboard not found');
        }
        const report = await this.reportingApi.getReportConfigByDashboardKey(dashboardKey);
        return DashboardRepositoryImpl.mapDashboardToDomain(representation, report);
    }

    public async getDashboards(): Promise<Dashboard[]> {
        const representations = await this.dashboardsApi.getDashboards();
        // return Promise.all(representations.map(async (dashboardRepresentation) => {
        //     const report = await this.reportingApi.getReportConfigByDashboardKey(dashboardRepresentation.key);
        //     return DashboardRepositoryImpl.mapDashboardToDomain(dashboardRepresentation, report?.createAutomatically, report?.layouts !== undefined);
        // }));
        return representations.map((dashboardRepresentation) => DashboardRepositoryImpl.mapDashboardToDomain(dashboardRepresentation));
    }

    public async getDashboardsForPortfolio(portfolioKey: string): Promise<Dashboard[]> {
        const representations = await this.dashboardsApi.getDashboardsForPortfolio(portfolioKey);
        return Promise.all(representations.map(async (dashboardRepresentation) => {
            const report = await this.reportingApi.getReportConfigByDashboardKey(dashboardRepresentation.key);
            return DashboardRepositoryImpl.mapDashboardToDomain(dashboardRepresentation, report);
        }));
    }

    public async updateDashboard(dashboard: Dashboard): Promise<Dashboard> {
        const representationUpdated = await this.dashboardsApi.updateDashboard(dashboard.key, {
            name: dashboard.name,
            order: dashboard.order,
        });
        const dashboardUpdated = DashboardRepositoryImpl.mapDashboardToDomain(representationUpdated);
        dashboardUpdated.hasCustomReportLayout = dashboard.hasCustomReportLayout;
        dashboardUpdated.automaticReportingActive = dashboard.automaticReportingActive;
        return dashboardUpdated;
    }

    public async deleteDashboard(dashboard: Dashboard): Promise<void> {
        return this.dashboardsApi.deleteDashboard(dashboard.key);
    }

    public async createDashboard(portfolioKey: string, name: string, templateKey?: string): Promise<Dashboard> {
        const allDashboards = await this.getDashboardsForPortfolio(portfolioKey);
        const order = allDashboards.map((it) => it.order).reduce(ArrayUtils.reduceToHighest, 0) + 10;
        let createdDashboard: DashboardRepresentation;
        if (templateKey) {
            createdDashboard = await this.dashboardsApi.createDashboardFromTemplate({ name: name, portfolioKey: portfolioKey }, templateKey);
            createdDashboard = await this.dashboardsApi.updateDashboard(createdDashboard.key, { order: order });
        } else {
            createdDashboard = await this.dashboardsApi.createDashboard({ name: name, portfolioKey: portfolioKey, order: order });
        }
        return DashboardRepositoryImpl.mapDashboardToDomain(createdDashboard);
    }

    private static mapDashboardToDomain(representation: DashboardRepresentation, report?: ReportConfigRepresentation): Dashboard {
        return {
            key: representation.key,
            portfolioKey: representation.portfolioKey,
            name: representation.name,
            updated: new Date(representation.updated),
            order: representation.order,
            widgetCount: representation.widgetCount,
            automaticReportingActive: report?.createAutomatically === true,
            hasCustomReportLayout: report?.layouts !== undefined,
        };
    }
}
