import Vue from 'vue';
import type VueRouter from 'vue-router';
import { DefaultUserRepository } from '@/plugins/modules/default/DefaultUserRepository';
import { Module, ModuleOptions } from '@/modules/ctx-report-preview';
import { Port } from '@/modules/shared/Port';
import DefaultI18nRepository from '@/plugins/modules/default/DefaultI18nRepository';
import { ReportConfigRepositoryImpl } from './ReportConfigRepositoryImpl';
import { GeneratorRepositoryImpl } from './GeneratorRepositoryImpl';
import { WidgetRepositoryImpl } from './WidgetRepositoryImpl';
import { DashboardRepositoryImpl } from './DashboardRepositoryImpl';

let initialized = false;

export function registerModule(router: VueRouter): void {
    if (initialized) {
        return;
    }
    initialized = true;
    Vue.use<ModuleOptions>(Module, {
        router: router,
        generatorRepository: new GeneratorRepositoryImpl({
            generators: Port.generators,
            parks: Port.parks,
        }),
        widgetRepository: new WidgetRepositoryImpl({
            widgets: Port.widgets,
            generators: Port.generators,
            parks: Port.parks,
            accounting: Port.accounting,
            portfolios: Port.portfolios,
            metrics: Port.metrics,
        }),
        dashboardRepository: new DashboardRepositoryImpl({
            dashboards: Port.dashboards,
        }),
        reportConfigRepository: new ReportConfigRepositoryImpl({
            reporting: Port.reporting,
            dashboards: Port.dashboards,
            portfolios: Port.portfolios,
            auth: Port.auth,
            widgets: Port.widgets,
            generators: Port.generators,
            parks: Port.parks,
            accounting: Port.accounting,
            metrics: Port.metrics,
        }),
        userRepository: new DefaultUserRepository({
            auth: Port.auth,
            license: Port.license,
        }),
        i18nRepository: new DefaultI18nRepository({
            localizations: Port.localizations,
        }),
    });
}
