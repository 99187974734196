import { v4 as generateUUID } from 'uuid';
import type { WidgetConfig } from '@/modules/ctx-dashboard';
import { WidgetType } from '@/modules/ctx-dashboard';
import { Aggregation, Resolution, ResourceType } from '@/clients/dashboardapi/v2';
import { WidgetPreset } from '@/components/widget-wizard';
import { Port } from '@/modules/shared/Port';
import { LicenseFeature } from '@/modules/shared/types';

export default class PowerDeviations implements WidgetPreset {

    public readonly name: string = 'performance-deviations';
    public readonly type: WidgetType = WidgetType.Heatmap;
    public readonly quickAdd: boolean = true;
    public readonly order: number = 0;
    public readonly metrics: string[] = ['virt-power-deviation'];
    public readonly requireLicenseFeatures: LicenseFeature[] = [LicenseFeature.ALERTING];
    public readonly hidden: boolean = true;

    public async createWidget(params: { portfolio: string; dashboard: string }): Promise<WidgetConfig> {
        return {
            key: '',
            portfolioKey: params.portfolio,
            dashboardKey: params.dashboard,
            order: 0,
            config: {},
            type: this.type,
            preset: this.name,
            title: '',
            generatedTitle: '',
            intervalName: '1W',
            intervalTo: new Date(),
            intervalFrom: new Date(),
            resolution: Resolution.Automatic,
            axis: [{
                unit: 'kW',
                name: '',
                config: {},
                metrics: [{
                    uuid: generateUUID().substring(0, 8),
                    metricKey: 'virt-power-deviation',
                    metricName: '',
                    metricCategory: '',
                    metricUnit: '',
                    aggregationOverTime: Aggregation.Avg,
                    resources: [{
                        uuid: generateUUID().substring(0, 8),
                        seriesName: '',
                        resourceName: (await Port.portfolios.getPortfolioByKey(params.portfolio))?.name || '',
                        type: ResourceType.Portfolio,
                        resourceKey: params.portfolio,
                        aggregationOverGenerators: Aggregation.Sum,
                        config: {},
                    }],
                }],
            }],
        };
    }
}
